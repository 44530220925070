import { default as $ } from 'jquery';
import 'slick-carousel';
import { getArrowIcon, SLICK_CONF } from '../slick-common/slick-common';

$(document).ready( function() {
    $('.m-collection-carousel.-isCarousel').each( function() {
        
        let slickConf = {
            ...SLICK_CONF,
            dots: false,
            infinite: true,
            margin: 20,
            slidesToShow: 3,
            arrows: true,
            draggable: true,
            appendDots: jQuery(this).parent(),
            appendArrows: jQuery(this).parent(),
            customPaging: function(slider, i) {
                return '<button type="button" data-role="none" role="button" tabindex="0">' + i + '</button>';
            },
            pauseOnHover: false,
            slidesToScroll: 1,
            controls: true,
            autoplay: false,
            autoplaySpeed: 4000,
            responsive: [
                {
                    breakpoint: 993,
                    settings: {
                        slidesToShow: 2,
                        arrows: true,

                    }
                },
                {
                    breakpoint: 768,

                    settings: {
                        slidesToShow: 1,
                        arrows: true,
                    }
                }
            ]
        };

        if ($(this).hasClass('m-collection-carousel')) {
            slickConf.prevArrow = '<button type="button" class="slick-prev"> </button>';
            slickConf.nextArrow = '<button type="button" class="slick-next"></button>';
        }

        $(this).slick(slickConf);
    });
});