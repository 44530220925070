import 'jquery';



jQuery(document).on("scroll", function() {
    var pageTop = jQuery(document).scrollTop();
    var pageBottom = pageTop + jQuery(window).height();
    var tags = jQuery(".start-animation");
  
    for (var i = 0; i < tags.length; i++) {
      var tag = tags[i];
  
      if (jQuery(tag).position().top < pageBottom) {
        jQuery(tag).addClass("fade-in");
      } else {
        jQuery(tag).removeClass("fade-in");
      }
    }
  });
  