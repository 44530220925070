import { default as $ } from 'jquery';
import 'slick-carousel';
import { getArrowIcon, SLICK_CONF } from '../slick-common/slick-common';

$(document).ready( function() {
    $('.m-gallery-row.-isCarousel').each( function() {
        
        let slickConf = {
            ...SLICK_CONF,
            dots: false,
            infinite: true,
            margin: 20,
            slidesToShow: 4,
            arrows: false,
            draggable: true,
            customPaging: function(slider, i) {
                return '<button type="button" data-role="none" role="button" tabindex="0">' + i + '</button>';
            },
            pauseOnHover: false,
            slidesToScroll: 1,
            controls: true,
            autoplay: true,
            autoplaySpeed: 4000,
            responsive: [
                {
                    breakpoint: 980,
                    settings: {
                        slidesToShow: 2,

                    }
                },
                {
                    breakpoint: 767,

                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        };

        $(this).slick(slickConf);
    });
});