// function initMap() {
//     const myLatLng = { lat: 56.766979, lng: -3.84559 };
//     const map = new google.maps.Map(document.getElementById("map"), {
//       zoom: 4,
//       center: myLatLng,
//     });
  
//     new google.maps.Marker({
//       position: myLatLng,
//       map,
//       title: "Blair Estate",
//     });
//   };
  